@font-face {
  font-family: 'SourceSansPro-Regular';
  src: local('SourceSansPro-Regular'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: local('SourceSansPro-SemiBold'),
    url(./assets/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gellix';
  src: url(./assets/fonts/GellixRegular.otf) format('opentype');
}

@font-face {
  font-family: 'Gellix';
  font-weight: bold;
  src: url(./assets/fonts/GellixBold.otf) format('opentype');
}

@font-face {
  font-family: 'PT-Serif';
  src: url(./assets/fonts/PT_Serif/PTSerif-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Fraunces';
  font-weight: bold;
  src: url(./assets/fonts/Fraunces/Fraunces72pt-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Fraunces';
  src: url(./assets/fonts/Fraunces/Fraunces72pt-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'PP-Neue-Machina';
  font-weight: light;
  src: url(./assets/fonts/PPNeueMachina/PPNeueMachina-PlainLight.otf) format('opentype');
}

@font-face {
  font-family: 'PP-Neue-Machina';
  src: url(./assets/fonts/PPNeueMachina/PPNeueMachina-PlainRegular.otf) format('opentype');
}

@font-face {
  font-family: 'PP-Neue-Machina';
  font-weight: bold;
  src: url(./assets/fonts/PPNeueMachina/PPNeueMachina-PlainUltrabold.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: medium;
  src: url(./assets/fonts/Inter/Inter-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: regular;
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('opentype');
}


@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: light;
  src: url(./assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url(./assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: bold;
  src: url(./assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf) format('opentype');
}

html {
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Gellix', 'SourceSansPro-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'PP-Neue-Machina', 'Inter',
    'Space-Grotesk'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Base create-reat-app div */
#root {
  margin: 0;
  height: 100%;
  width: 100%;
}
