.smartbanner-show.smartbanner-margin-top {
    margin-top: 80px
}

.smartbanner-show.smartbanner-margin-bottom {
    margin-bottom: 80px
}

.smartbanner-show .smartbanner {
    display: block;
    background: #fff
}

.smartbanner {
    left: 0;
    display: none;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-family: Helvetica Neue, sans-serif;
    background: #f4f4f4;
    z-index: 9998;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    -webkit-text-size-adjust: none
}

.smartbanner-top {
    position: absolute;
    top: 0
}

.smartbanner-bottom {
    position: fixed;
    bottom: 0
}

.smartbanner-container {
    margin: 0 auto;
    padding: 0 5px
}

.smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    font-family: ArialRoundedMTBold, Arial;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    padding: 0 0 1px;
    background-color: transparent;
    -webkit-font-smoothing: subpixel-antialiased
}

.smartbanner-close:active, .smartbanner-close:hover {
    color: #aaa
}

.smartbanner-icon {
    width: 57px;
    height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px
}

.smartbanner-icon, .smartbanner-info {
    display: inline-block;
    vertical-align: middle
}

.smartbanner-info {
    white-space: normal;
    width: calc(99% - 201px);
    font-size: 11px;
    line-height: 1.2em;
    font-weight: 700
}

.smartbanner-wrapper {
    max-width: 110px;
    display: inline-block;
    text-align: right;
    width: 100%
}

.smartbanner-title {
    font-size: 13px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.smartbanner-description {
    max-height: 40px;
    overflow: hidden
}

.smartbanner-author {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.smartbanner-author:empty + .smartbanner-description {
    max-height: 50px
}

.smartbanner-button {
    margin: auto 0;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: #6a6a6a;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8)
}

.smartbanner-button:active, .smartbanner-button:hover {
    color: #aaa
}

.smartbanner .smartbanner-close {
    border: 0;
    max-width: 17px;
    width: 100%;
    height: 17px;
    line-height: 17px;
    margin-right: 7px;
    color: #b1b1b3;
    background: #1c1e21;
    text-shadow: 0 1px 1px #000;
    text-decoration: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .8), 0 1px 1px hsla(0, 0%, 100%, .3);
    cursor: pointer
}

.smartbanner .smartbanner-close:active, .smartbanner .smartbanner-close:hover {
    color: #eee
}

.smartbanner .smartbanner-icon {
    background-color: transparent;
    box-shadow: none
}

.smartbanner .smartbanner-info {
    color: #ccc;
    text-shadow: 0 1px 2px #000
}

.smartbanner .smartbanner-title {
    color: #fff;
    font-weight: 700
}

.smartbanner .smartbanner-button {
    min-width: 12%;
    color: #d1d1d1;
    font-weight: 700;
    padding: 0;
    background: none;
    border-radius: 0;
    box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc
}

.smartbanner .smartbanner-button:active, .smartbanner .smartbanner-button:hover {
    background: none
}

.smartbanner .smartbanner-button-text {
    text-align: center;
    display: block;
    padding: 0 10px;
    background: #42b6c9;
    background: linear-gradient(180deg, #42b6c9, #39a9bb);
    text-transform: none;
    text-shadow: none;
    box-shadow: none
}

.smartbanner .smartbanner-button-text:active, .smartbanner .smartbanner-button-text:hover {
    background: #2ac7e1
}

.smartbanner .smartbanner-button {
    text-transform: none;
    border-radius: 60px;
    font-family: 'Fraunces';
    font-weight: 700;
    line-height: 1.2rem;
    padding: .5rem 1.5rem;
    background: #000;
    color: #fff;
    font-size: 1rem
}

.smartbanner .smartbanner-button-text {
    background: none;
    padding: 0;
    font-family: 'Fraunces';
    text-transform: lowercase;
    font-size: 1.2rem
}

.smartbanner .smartbanner-info {
    color: #000;
    text-shadow: none;
    font-family: 'Fraunces';
    font-size: 12px
}

.smartbanner .smartbanner-title {
    font-family: 'Fraunces';
    color: #000;
    font-size: 15px
}

.orelo-mobile-app .smartbanner {
    display: none !important;
}